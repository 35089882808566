<template>
  <div>
    <router-link class="come-back" to="/admin/corruption">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование структуры' : 'Создание структуры' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddCorruption" ref="AddCorruption">
        <div class="content-sb">
          <p>Название пункта<span>*</span></p>
          <input required placeholder="Введите название пункта" v-model="form.title" type="text" name="title" class="input blue">
        </div>

      <div class="content-sb">
        <p>Ссылка на страницу<span>*</span></p>
        <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="form.text"></ckeditor>
      </div>

      <div class="content-sb">
        <p>Состав<span>*</span></p>
        <select required  ref="selectYear" class="input blue" name="convocation">
          <option v-if="$route.query.type === 'create'" disabled selected>Выберите состав</option>
          <option v-for="item in $store.getters.getConvocation"
                  :selected="form.convocation === item.id"
                  :key="`getConvocation${item.id}`" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="content-sb">
        <p>Впишите названия разделов, под которыми<br/> должна отображаться эта запись </p>
        <tags @dataTags="dataTags" :startValue="form.tagList" placeholder="Впишите названия разделов" />
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/corruption" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createStructure',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailStructure : {
        tagList: [],
        title: '',
        text: ''
      }
    }
  },
  methods: {
    AddCorruption () {
      const formData = new FormData(this.$refs.AddCorruption)
      formData.append('tagList', JSON.stringify(this.form.tagList))
      formData.append('text', this.form.text)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editStructure', { data: formData, id: this.form.id })
      } else {
        this.$store.dispatch('createStructure', formData)
      }
    },
    dataTags (value) {
      this.form.tagList = value
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailStructure === null) {
      this.$router.push('/admin/structure')
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
